body {
  font-family: "Roboto", "open-sans", sans-serif;
  position: relative;
  color: #191919;
  background-color: #fff;
}
body.bg {
  background: url(/media/images/bg_cabinet.jpg);
  background-size: cover;
}
body.bg .wrapper {
  background-image: none;
}
header {
  box-shadow: -8px 8px 58px -21px rgba(0, 0, 0, 0.55);
  background-color: #fffc;
}
header .top-line {
  background-color: #f6f6f6;
  height: 42px;
  line-height: 1.2;
}
header .top-line ul {
  display: flex;
}
header .top-line .list-menu {
  font-size: 14px;
  margin: 11px 0;
}
header .top-line .list-menu li {
  padding: 0 15px 0 10px;
}
header .top-line .list-menu li:not(:last-child) {
  border-right: 1px solid #616161;
}
header .top-line .list-menu a {
  color: #616161;
}
header .top-line .list-menu a:hover {
  text-decoration: none;
}
header .top-line .list-user-menu {
  margin: 10px 0;
  color: #191919;
  font-size: 18px;
  font-weight: bold;
}
header .top-line .list-user-menu li {
  margin: 0 15px;
}
header .top-line .list-user-menu a {
  color: inherit;
}
header .top-line .list-user-menu a:hover {
  color: #1a1a1a;
  text-decoration: none;
}
header .top-line .list-user-menu .phone {
  margin-right: 35px;
}
header .top-line .list-user-menu .phone img {
  margin-top: -5px;
}
header .logo {
  text-align: center;
  padding: 15px 0;
}
header .list-main-menu {
  display: flex;
  justify-content: space-between;
  margin: 0;
}
header .list-main-menu a {
  display: block;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  color: inherit;
  border-bottom: 4px solid transparent;
  padding: 5px 15px;
  text-decoration: none;
}
header .list-main-menu a:hover,
header .list-main-menu a.active,
header .list-main-menu a:active {
  color: #35cbc6;
  text-decoration: none;
  border-color: #bd965f;
}
footer {
  background-color: #f5f5f5c9;
}
footer .top-line {
  background-color: #e8e8e8;
  padding: 15px 0;
}
footer .top-line form {
  display: flex;
  justify-content: space-between;
}
footer .top-line form .text {
  font-size: 18px;
  font-weight: bold;
  width: 430px;
  padding: 5px 0;
}
footer .top-line form input {
  width: 315px;
  border: none;
  margin-right: 30px;
}
footer .top-line form button {
  background-color: transparent;
  padding: 0;
  margin: 0;
  border: none;
}
footer .footer-text {
  padding: 35px 0 20px;
  font-size: 16px;
}
footer .footer-text .text-email {
  margin-bottom: 30px;
}
footer .footer-text h3 {
  margin: 0 0 20px;
  padding: 0;
  font-size: 22px;
}
footer .footer-text ul a {
  color: inherit;
  margin: 5px 0;
}
footer .footer-text ul a:hover {
  color: #191919;
}
.wrapper {
  min-height: 500px;
  padding: 25px 0;
  background: url(/media/images/bg_left.png) bottom left -150px no-repeat;
}
.tree {
  max-width: 100%;
  overflow: hidden;
}
.promo-slider-top {
  margin-bottom: 15px;
}
.promo-slider-top .swiper-button-next,
.promo-slider-top .swiper-button-prev {
  filter: brightness(0) invert(100%);
}
.promo-slider-top .swiper-pagination-bullet-active {
  background-color: #fff;
}
.site-info {
  text-align: center;
  font-size: 16px;
  margin-bottom: 20px;
}
.site-tutorial img {
  max-width: 100%;
}
.text-header {
  color: #36c0bc;
  font-size: 22px;
  text-transform: uppercase;
  margin-bottom: 30px;
}
.category-image {
  height: 400px;
  width: 100%;
  margin-bottom: 15px;
}
.category-image .category-title {
  position: absolute;
  bottom: 28px;
  left: 18px;
  padding: 10px 30px;
  background-color: rgba(255, 255, 255, 0.6);
  text-transform: uppercase;
  text-align: center;
  font-size: 26px;
}
.category-image .category-title a {
  display: block;
  border: 1px solid #000;
  padding: 3px 10px;
  width: 125px;
  margin: 10px auto 0;
  color: #000;
  font-size: 18px;
}
.category-image .category-title a:hover {
  text-decoration: none;
}
.breadcrumb {
  background-color: transparent;
}
.bread-crumbs {
  margin-bottom: 20px;
  line-height: 1;
}
.tab-content-static {
  padding: 20px;
  border: 1px solid #e7e7e7;
  border-top: none;
}
.breadcrumb-container {
  background-color: rgba(255, 255, 255, 0.9);
  margin-bottom: 15px;
}
.breadcrumb-container .breadcrumb {
  margin-bottom: 0;
}
.page {
  padding: 15px 20px;
  background-color: rgba(255, 255, 255, 0.9);
  min-height: 300px;
}
.admin-photos a {
  text-decoration: none;
}
.file-upload-box .list,
.admin-photos {
  overflow: hidden;
  padding-top: 10px;
}
.file-upload-box .list p,
.admin-photos a {
  float: left;
  height: 180px;
  width: 180px;
  overflow: hidden;
  margin: 5px;
  background-color: #f1f1f1;
  border: 1px solid #efefef;
  cursor: pointer;
}
.file-upload-box .list p:hover,
.admin-photos a:hover {
  border-color: darkred;
}
.bucket-add {
  cursor: pointer;
  border-color: transparent;
  background-color: #225297;
  color: #fff;
}
.bucket-add:hover,
.bucket-add:active {
  color: #fff;
}
.form-find {
  margin-bottom: 20px;
}
.form-inline .form-group {
  margin-right: 15px;
}
.form-inline .form-control {
  margin-left: 10px;
}
.form-order-action label {
  display: block;
}
.form-order-action .btn {
  margin-right: 15px;
}
.product-item-list {
  display: block;
  color: inherit;
  text-decoration: none;
}
.product-item-list:hover {
  text-decoration: none;
  color: inherit;
}
.product-item-list .product-photo {
  padding-top: 100%;
  position: relative;
  background-size: cover;
  margin-bottom: 10px;
  border: 1px solid #eee;
}
.product-item-list .product-photo .product-card {
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
  padding: 5px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #eee;
}
.product-item-list .product-photo .product-card .bucket-add {
  display: block;
}
.product-item-list .product-photo:hover .product-card {
  opacity: 1.0;
}
.product-item-list .product-title {
  font-size: 17px;
  color: #888888;
}
.products-list .product-item-list {
  margin-bottom: 30px;
}
.btn-dotted {
  background-color: transparent;
  border: 1px solid #000;
  color: #000;
  text-transform: uppercase;
  font-size: 16px;
}
.product-item-full .row {
  margin-bottom: 20px;
}
.product-item-full .product-price {
  width: 300px;
}
.product-item-full .product-desc {
  padding-top: 22.5px;
}
.product-item-full .product-photos {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
  width: 100%;
}
.product-item-full .product-photos a {
  margin-right: 10px;
  margin-bottom: 10px;
}
table caption {
  padding-top: 0;
  padding-bottom: 4px;
}
table th {
  background-color: #4983d6;
  color: #fff;
}
.page-bucker .order-amount caption {
  display: none;
}
form label {
  text-transform: uppercase;
}
.bucket-link {
  position: relative;
}
.bucket-link img {
  width: 22px;
  margin-top: -3px;
  margin-left: -5px;
}
.bucket-link .bucket-link-score {
  display: inline-block;
  font-size: 16px;
  background-color: #e64a19;
  border: 1px solid #b83b14;
  border-radius: 50px;
  color: #fff;
  width: 25px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  margin-left: -3px;
  margin-top: -5px;
}
.search-link img {
  margin-top: -3px;
}
.cabinet-menu-container {
  background: #fff;
  border-bottom: 2px solid #fff1cd;
}
.cabinet-menu {
  height: 157px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: Arial, serif;
  font-size: 16px;
  color: #07728b;
  margin: 0 -6px;
  padding: 0;
}
.cabinet-menu li {
  padding: 30px 20px 3px 20px;
  text-transform: uppercase;
  text-align: center;
}
.cabinet-menu li.active {
  background: #fff1cd;
}
.cabinet-menu li img {
  display: inline-block;
  margin: 0 0 22px 0;
  padding: 0;
}
.cabinet-menu li a {
  display: block;
}
.cabinet-menu li a:hover {
  text-decoration: none;
}
.admin-menu {
  border-top: 2px solid #fff1cd;
  background-color: #fff;
  padding: 5px 20px;
}
.admin-menu ul {
  margin: 10px 0;
}
.user-info {
  font-size: 24px;
}
.user-info img {
  margin-top: 2px;
  width: 100%;
  background: #fff;
  border: 1px solid #333;
}
.user-info ul {
  margin-bottom: 32px;
}
.user-info-content {
  padding-left: 40px;
}
.form-userphoto {
  overflow: hidden;
  text-align: center;
  padding: 10px 0;
}
.form-userphoto label {
  font-size: 24px;
  font-weight: normal;
  text-transform: lowercase;
  text-decoration: underline;
  cursor: pointer;
}
.form-userphoto input[type=file] {
  cursor: pointer;
  opacity: 0;
  margin-top: -20px;
}
.form-userphoto .form-actions {
  display: none;
}
.program-page ul {
  font-size: 20px;
}
.program-page ul img {
  margin-top: -3px;
  margin-right: 8px;
}
.page-register .form-group {
  overflow: hidden;
}
.page-register .form-group label {
  width: 200px;
  float: left;
  line-height: 50px;
}
.page-register .form-group .form-control {
  width: 500px;
  float: right;
}
.page-register .form-group .help-block {
  display: block;
  clear: both;
  text-align: right;
  text-transform: uppercase;
}
.page-register .agreement-field label {
  clear: both;
  width: 100%;
}
.page-register .form-control-static {
  display: none;
}
.g-recaptcha {
  padding-left: 237px;
  margin-bottom: 20px;
}
