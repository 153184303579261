@import "./bootstrap/variables";

body {
  font-family: "Roboto", "open-sans", sans-serif;
  position: relative;
  color: @text-color;
  background-color: #fff;

  &.bg {
    background: url(/media/images/bg_cabinet.jpg);
    background-size: cover;

    .wrapper {
      background-image: none;
    }
  }
}

// Макет
header {
  box-shadow: -8px 8px 58px -21px rgba(0,0,0,0.55);
  background-color: #fffc;

  .top-line {
    background-color: #f6f6f6;
    height: 42px;
    line-height: 1.2;

    ul {
      display: flex;
    }

    .list-menu {
      font-size: 14px;
      margin: 11px 0;

      li {
        padding: 0 15px 0 10px;

        &:not(:last-child) {
          border-right: 1px solid @text-gray;
        }
      }

      a {
        color: @text-gray;

        &:hover {
          text-decoration: none;
        }
      }
    }

    .list-user-menu {
      margin: 10px 0;
      color: @text-color;
      font-size: 18px;
      font-weight: bold;

      li {
        margin: 0 15px;
      }

      a {
        color: inherit;

        &:hover {
          color: lighten(@text-color, 0.3);
          text-decoration: none;
        }
      }

      .phone {
        margin-right: 35px;

        img {
          margin-top: -5px;
        }
      }
    }
  }

  .logo {
    text-align: center;
    padding: 15px 0;
  }

  .list-main-menu {
    display: flex;
    justify-content: space-between;
    margin: 0;

    a {
      display: block;
      font-size: 18px;
      font-weight: bold;
      text-transform: uppercase;
      color: inherit;
      border-bottom: 4px solid transparent;
      padding: 5px 15px;
      text-decoration: none;

      &:hover, &.active, &:active {
        color: #35cbc6;
        text-decoration: none;
        border-color: #bd965f;
      }
    }
  }
}

footer {
  background-color: #f5f5f5c9;

  .top-line {
    background-color: #e8e8e8;
    padding: 15px 0;

    form {
      display: flex;
      justify-content: space-between;

      .text {
        font-size: 18px;
        font-weight: bold;
        width: 430px;
        padding: 5px 0;
      }

      input {
        width: 315px;
        border: none;
        margin-right: 30px;
      }

      button {
        background-color: transparent;
        padding: 0;
        margin: 0;
        border: none;
      }
    }
  }

  .footer-text {
    padding: 35px 0 20px;
    font-size: 16px;

    .text-email {
      margin-bottom: 30px;
    }

    h3 {
      margin: 0 0 20px;
      padding: 0;
      font-size: 22px;
    }

    ul {
      a {
        color: inherit;
        margin: 5px 0;

        &:hover {
          color: @text-color;
        }
      }
    }
  }
}

.wrapper {
  min-height: 500px;
  padding: 25px 0;

  background: url(/media/images/bg_left.png) bottom left -150px no-repeat;
}

.tree {
  max-width: 100%;
  overflow: hidden;
}

.promo-slider-top {
  margin-bottom: 15px;

  .swiper-button-next, .swiper-button-prev {
    filter: brightness(0) invert(100%);
  }

  .swiper-pagination-bullet-active {
    background-color: #fff;
  }
}

.site-info {
  text-align: center;
  font-size: 16px;
  margin-bottom: 20px;
}

.site-tutorial {
  img {
    max-width: 100%;
  }
}

.text-header {
  color: #36c0bc;
  font-size: 22px;
  text-transform: uppercase;
  margin-bottom: 30px;
}

.category-image {
  height: 400px;
  width: 100%;
  margin-bottom: 15px;

  .category-title {
    position: absolute;
    bottom: 28px;
    left: 18px;

    padding: 10px 30px;
    background-color: rgba(255, 255, 255, 0.6);
    text-transform: uppercase;
    text-align: center;
    font-size: 26px;

    a {
      display: block;
      border: 1px solid #000;
      padding: 3px 10px;
      width: 125px;
      margin: 10px auto 0;
      color: #000;
      font-size: 18px;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

.breadcrumb {
  background-color: transparent;
}

.bread-crumbs {
  margin-bottom: 20px;
  line-height: 1;
}

.tab-content-static {
  padding: 20px;
  border: 1px solid #e7e7e7;
  border-top: none;
}

.breadcrumb-container {
  background-color: rgba(255, 255, 255, 0.9);
  margin-bottom: 15px;

  .breadcrumb {
    margin-bottom: 0;
  }
}

.page {
  padding: 15px 20px;
  background-color: rgba(255, 255, 255, 0.9);
  min-height: 300px;
}

.admin-photos a{text-decoration:none;}

.file-upload-box .list, .admin-photos{overflow:hidden;padding-top:10px;}
.file-upload-box .list p, .admin-photos a{float:left; height:180px; width: 180px; overflow:hidden;margin:5px;background-color:#f1f1f1;border:1px solid #efefef;cursor:pointer;}
.file-upload-box .list p:hover, .admin-photos a:hover{border-color:darkred;}

// Bucket
.bucket-add {
  cursor: pointer;
  border-color: transparent;
  background-color: #225297;
  color: #fff;

  &:hover, &:active {
    color: #fff;
  }
}

.form-find {
  margin-bottom: 20px;
}

.form-inline {
  .form-group {
    margin-right: 15px;
  }

  .form-control {
    margin-left: 10px;
  }
}

.form-order-action {
  label {
    display: block;
  }
  .btn {
    margin-right: 15px;
  }
}

.product-item-list {
  display: block;
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    color: inherit;
  }

  .product-photo {
    padding-top: 100%;
    position: relative;
    background-size: cover;
    margin-bottom: 10px;
    border: 1px solid #eee;

    .product-card {
      transition: opacity 0.5s ease-in-out;
      opacity: 0;
      padding: 5px;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      background-color: #eee;

      .bucket-add {
        display: block;
      }
    }

    &:hover .product-card {
      opacity: 1.0;
    }
  }

  .product-title {
    font-size: 17px;
    color: #888888;
  }
}

.products-list {
  .product-item-list {
    margin-bottom: 30px;
  }
}

.btn-dotted {
  background-color: transparent;
  border: 1px solid #000;
  color: #000;
  text-transform: uppercase;
  font-size: 16px;
}

.product-item-full {
  .row {
    margin-bottom: 20px;
  }

  .product-price {
    width: 300px;
  }

  .product-desc {
    padding-top: 22.5px;
  }

  .product-photos {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;
    width: 100%;

    a {
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }
}

table {
  caption {
    padding-top: 0;
    padding-bottom: 4px;
  }

  th {
    background-color: lighten(#225297, 20%);
    color: #fff;
  }
}

.page-bucker {
  .order-amount caption {
    display: none;
  }
}

form {
  label {
    text-transform: uppercase;
  }
}

.bucket-link {
  position: relative;

  img {
    width: 22px;
    margin-top: -3px;
    margin-left: -5px;
  }

  .bucket-link-score {
    display: inline-block;
    font-size: 16px;
    background-color: #e64a19;
    border: 1px solid darken(#e64a19, 10%);
    border-radius: 50px;
    color: #fff;
    width: 25px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    margin-left: -3px;
    margin-top: -5px;
  }
}

.search-link {
  img {
    margin-top: -3px;
  }
}

.cabinet-menu-container {
  background: #fff;
  border-bottom: 2px solid #fff1cd;
}

.cabinet-menu {
  height: 157px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: Arial, serif;
  font-size: 16px;
  color: #07728b;
  margin: 0 -6px;
  padding: 0;

  li {
    padding: 30px 20px 3px 20px;
    text-transform: uppercase;
    text-align: center;

    &.active {
      background: #fff1cd;
    }

    img {
      display: inline-block;
      margin: 0 0 22px 0;
      padding: 0;
    }

    a {
      display: block;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

.admin-menu {
  border-top: 2px solid #fff1cd;
  background-color: #fff;
  padding: 5px 20px;

  ul {
    margin: 10px 0;
  }
}

.user-info {
  img {
    margin-top: 2px;
    width: 100%;
    background: #fff;
    border: 1px solid #333;
  }

  font-size: 24px;

  ul {
    margin-bottom: 32px;
  }

  &-content {
    padding-left: 40px;
  }
}

.form-userphoto {
  overflow: hidden;
  text-align: center;
  padding: 10px 0;

  label {
    font-size: 24px;
    font-weight: normal;
    text-transform: lowercase;
    text-decoration: underline;
    cursor: pointer;
  }

  input[type=file] {
    cursor: pointer;
    opacity: 0;
    margin-top: -20px;
  }

  .form-actions {
    display: none;
  }
}

.program-page {
  ul {
    font-size: 20px;

    img {
      margin-top: -3px;
      margin-right: 8px;
    }
  }
}

.page-register {
  .form-group {
    overflow: hidden;

    label {
      width: 200px;
      float: left;
      line-height: 50px;
    }

    .form-control {
      width: 500px;
      float: right;
    }

    .help-block {
      display: block;
      clear: both;
      text-align: right;
      text-transform: uppercase;
    }
  }

  .agreement-field label {
    clear: both;
    width: 100%;
  }

  .form-control-static {
    display: none;
  }
}

.g-recaptcha {
  padding-left: 237px;
  margin-bottom: 20px;
}
